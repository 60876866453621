<template>
  <div>
    <div class="pop-container">
      <div class="pop-conts">
        <div class="box_list_2row box_bd">
          <common-multi-drop-down
              ref="cmpyNmRef"
              v-model="costStdInfo.cmpyCd"
              :params="{ searchCmpyGbnGrup : [Constant.efs.cmpyGbn.CLIENT_COMPANY] }"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
              :data-value-field="'cmpyCd'"
              :data-text-field="'cmpyNm'"
              :placeholder="'회사선택'"
              class="w50"
              @change="value=>{
                  propsCmpyCd = value
                  if(!!propsCmpyCd) getDataArray()
                }"
          >
          </common-multi-drop-down>
          <ul class="mgt20">
            <li class="left w50">
              <button :disabled="!propsCmpyCd" class="mid_btn fr" @click="onClickExcelDownload"><i class="download_icon"></i>엑셀 다운로드</button>
              <h2 class="mgt10">원가 정보 이력</h2>
              <kendo-grid
                  ref="costStdGridRef"
                  class="mgt20"
                  :navigatable="false"
                  :reorderable="true"
                  :column-menu="false"
                  :sortable-mode="'multiple'"
                  :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
                  :selectable="'row'"
                  :data-source="costStdListDataSource"
                  :columns="costStdGridColumns"
                  @change="onChangeGrid"
                  :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}"
              >
              </kendo-grid>
            </li>
            <li class="right">
              <button :disabled="!propsCmpyCd" @click="onClickCostStdAdd" class="mid_btn f_r orange">신규기준생성</button>
              <h2 class="mgt10">원가산출 정보입력</h2>
              <div class="box_moc mgt20">
                <table class="moc_table " cellpadding="0" cellspacing="0">
                  <colgroup>
                    <col width="30%" />
                    <col width="*" />
                  </colgroup>
                  <tr>
                    <td class="txt_c bg_g necessary">
                      기준일자
                    </td>
                    <td v-if="costStdInfo.isEdit">
                      <span>
                        <kendo-datepicker
                            ref="calcStdDateRef"
                            v-model="costStdInfo.VModelCalcStdDate"
                            :format="'yyyy/MM/dd'"
                            :placeholder="'년/월/일'"
                        ></kendo-datepicker>
                      </span>
                    </td>
                    <td v-else>{{ costStdInfo.calcStdDate }}</td>
                  </tr>
                  <tr>
                    <td class="txt_c bg_g necessary ">
                      분할파트
                    </td>
                    <td>
                      <common-radio-group
                          ref="prcsDepth1Ref"
                          :data-items='dataArray'
                          v-model.trim="costStdInfo.prcsDepth1"
                          :disabled="!costStdInfo.isEdit"
                          :data-value-field="'prcsDepth1'"
                          :data-text-field="'prcsDepth1Nm'"
                      ></common-radio-group>
                    </td>
                  </tr>
                  <tr>
                    <td class="txt_c bg_g necessary ">
                      인건비
                    </td>
                    <td v-if="costStdInfo.isEdit">
                      <span>
                        <kendo-numerictextbox ref="persnCostRef"
                                              class="w80"
                                              v-model="costStdInfo.persnCost"
                                              :format="'###,#'"
                                              :step="10000"
                                              :min="0">
                        </kendo-numerictextbox> 원
                      </span>
                    </td>
                    <td v-else-if="!!costStdInfo.persnCost">{{ costStdInfo.persnCost | comma }} 원</td>
                    <td v-else></td>
                  </tr>
                  <tr>
                    <td class="txt_c bg_g necessary ">
                      재경비
                    </td>
                    <td v-if="costStdInfo.isEdit">
                      <span>
                        <kendo-numerictextbox ref="finanRateRef"
                                              class="w80"
                                              :format="'###,#'"
                                              v-model="costStdInfo.finanRate"
                                              :max="100"
                                              :min="0">
                        </kendo-numerictextbox> %
                      </span>
                    </td>
                    <td v-else>{{ !!costStdInfo.finanRate ? costStdInfo.finanRate + '%' : '' }}</td>
                  </tr>
                </table>
              </div>
              <div class="button_area w50">
                <ul>
                  <li class="w30">
                    <button v-show="costStdInfo.isEdit" @click="onClickCostStdCancel" class="mid_btn">취소</button>
                  </li>
                  <li class="w30">
                    <button v-show="costStdInfo.isEdit" @click="onClickCostStdSave" class="mid_btn orange">저장</button>
                  </li>
                  <li>
                    <button v-show="!!costStdInfo.calcStdNo && !costStdInfo.isEdit" @click="editCostStdInfo" class="mid_btn ">수정</button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ApiUtil from "@/api/api.util";
import ApiConfig from "@/api/api.config";
import EtnersEfsUtil from '@/common/etners.efs.util'
import { mapGetters } from 'vuex'
import EtnersDateTimeUtil from '@ebmp-fe-common-module/module/src/common/etners.datetime.util'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import { saveExcel } from '@progress/kendo-vue-excel-export'
import Constant from '@/common/constant'


export default {
  name: 'StandardCostCalculation',
  computed : {
    costStdListDataSource: function () {
      const vm = this

      if(!vm.propsCmpyCd || vm.propsCmpyCd ===''){
        return []
      }

      const gridDataSource = new kendo.data.DataSource({
        name: 'gridDataSource',
        page: 1,
        pageSize: 10,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
          read: {
            url: `${ApiConfig.efsDomain}/efs/prod/cost/std`,
            type: 'GET',
            contentType: 'application/json',
            cache: false,
          },
          parameterMap: function (options, operation) {

            if (operation === 'read') {

              let sort = EtnersEfsUtil.convertJSONArrayToJSON('sort', options.sort)

              vm.costStdInfo = { cmpyCd: vm.propsCmpyCd}

              vm.lastRouteParams = {
                loginUserToken: vm.$store.state.loginStore.lutk,
                searchCmpyCd : vm.propsCmpyCd,
                skip: options.skip,
                take: options.take,
                page: options.page,
                pageSize: options.pageSize,
                ...sort,
              }
              return vm.lastRouteParams
            }

            if (operation !== 'read' && options.models) {
              return { models: kendo.stringify(options.models) }
            }
          },
        },
        schema: {
          type: 'json',
          parse: function (response) {
            let changeResultData = response.resultData
            let rowNumber = (vm.$refs.costStdGridRef.dataSource.page() - 1)
                * vm.$refs.costStdGridRef.dataSource.pageSize()
            for (let i = 0; i < changeResultData?.length; i++) {
              let row = changeResultData[i]
              row.rowNumber = response.total - rowNumber++
            }
            response.resultData = changeResultData
            return response
          },
          total: function (response) {
            return response.total
          },
          data: function (response) {
            if (response.resultStatus.messageCode === '2000') {
              return response.resultData
            }else if(response.resultStatus.messageCode === '4154'){
              vm.$store.dispatch('LOGOUT').then(() => {
                kendo.alert(response.resultStatus.messageText).bind('close', function() {
                  window.location.href = `/login`
                })
              })
            }else {
              kendo.alert( response.resultStatus.messageText)
              return ''
            }
          },
        },
        error: function (e) {
          console.log('error event handler', e.errors[0])
        },
      })
      return gridDataSource
    },
    ...mapGetters(['loginUserData'])
  },
  watch : {
    propsCmpyCd : function (newValue, oldValue){
      if(!newValue || newValue === ''){
        this.costStdInfo = {}
      }
    }
  },
  methods : {
    editCostStdInfo : function (){
      const vm = this
      // cloneDeep을 하지않으면 수정시 gird리스트의 값이 같이 바뀜
      let temp = _.cloneDeep(vm.costStdInfo)
      temp.isNew = false
      temp.isEdit = true
      vm.costStdInfo = temp
    },
    onClickCostStdCancel : function (){
      const vm = this
      vm.costStdInfo.isNew = false
      vm.costStdInfo.isEdit = false
      vm.getCostStdInfo()
    },
    onClickCostStdAdd : function (){
      const vm = this
      /** kendo 컴포넌트 특성상 객체인 value가 변경되어도 data상의 value는 바뀌지만
       * dom에는 값이 그대로 보여지고있기때문에 직접 kendo method로 초기화해줘야함
       **/
      vm.$refs.costStdGridRef.kendoWidget().dataSource.read()
      vm.$refs.calcStdDateRef?.kendoWidget().value(null)
      vm.$refs.persnCostRef?.kendoWidget().value(null)
      vm.$refs.finanRateRef?.kendoWidget().value(null)
      vm.costStdInfo = {
        isNew : true,
        isEdit : true,
        cmpyCd : vm.propsCmpyCd
      }
    },
    onChangeGrid (ev) {
      const vm = this
      let cellIdx = window.event.target.cellIndex

      if (!isNaN(cellIdx)) {
        let selectedRow = ev.sender.select()
        let rowData = ev.sender.dataItem(selectedRow)
        this.searchInfo = {
          calcStdNo : rowData.calcStdNo,
        }
        this.getCostStdInfo()
      }
    },
    getCostStdInfo : function (){
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/prod/cost/std/${vm.searchInfo.calcStdNo}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.costStdInfo = response.data.resultData
              vm.costStdInfo.calcStdNo = vm.searchInfo.calcStdNo
              vm.costStdInfo.VModelCalcStdDate = EtnersDateTimeUtil.lookupDateYYYYMMDD(EtnersCommonUtil.extractOnlyNumbers(vm.costStdInfo.calcStdDate))
              vm.costStdInfo.isNew = false
              vm.costStdInfo.isEdit = false
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    onClickCostStdSave () {
      const vm = this

      if (vm.validator()) {
        kendo.confirm(`저장 하시겠습니까?`).done(
            function () {
              vm.costStdSave()
            })
      }
    },
    costStdSave() {

      const vm = this
      let resultData

      if(!!vm.costStdInfo.VModelCalcStdDate){
        vm.costStdInfo.calcStdDate = EtnersDateTimeUtil.dateToYYYYMMDD(vm.costStdInfo.VModelCalcStdDate)
      }

      if(vm.costStdInfo.isNew){
        ApiUtil.post(`${ApiConfig.efsDomain}/efs/prod/cost/std`, vm.costStdInfo)
        .then(response => {

          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }

          resultData = response.data.resultData
          completeApi()

        })
      }else {
        ApiUtil.put(`${ApiConfig.efsDomain}/efs/prod/cost/std/${vm.costStdInfo.calcStdNo}`, vm.costStdInfo)
        .then(response => {

          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }

          resultData = response.data.resultData
          completeApi()

        })
      }

      function completeApi() {
        if(!!resultData){
          let costStdGridRef = vm.$refs.costStdGridRef.kendoWidget()
          costStdGridRef.dataSource.read()
          kendo.alert('저장되었습니다.').bind('close', function() {
            let girdData = costStdGridRef.items()
            girdData.each(i=>{
              let rowData = costStdGridRef.dataItem(girdData[i])
              if(rowData.calcStdNo === resultData){
                costStdGridRef.select(`tr:eq(${i})`)
                vm.searchInfo = {
                  calcStdNo : resultData,
                }
                vm.getCostStdInfo()
              }
            })
          })
        }else {
          kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
        }
      }
    },
    // onClickCostStdDelete : function (){
    //   const vm = this
    //   kendo.confirm(`삭제 하시겠습니까?`).done(function () {
    //     vm.deleteCostStd()
    //   })
    // },
    // deleteCostStd : function (){
    //   const vm = this
    //   ApiUtil.delete(`${ApiConfig.efsDomain}/efs/costStd/${vm.costStdInfo.cmpyCd}/${vm.costStdInfo.locaCd}/${vm.costStdInfo.costStdCd}`, vm.costStdInfo)
    //   .then(response => {
    //
    //     if (response.data === undefined || response.data.resultStatus === undefined) {
    //       kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
    //       return false
    //     }
    //     if (response.data.resultStatus.messageCode !== '2000') {
    //       kendo.alert(response.data.resultStatus.messageText)
    //       return false
    //     }
    //     vm.$refs.costStdGridRef.kendoWidget().dataSource.read()
    //     this.costStdInfo = {}
    //     kendo.alert("삭제되었습니다.")
    //   })
    // },
    getDataArray: function () {
      const vm = this
      let param = {
        searchCmpyCd : vm.propsCmpyCd,
        depthRange : 1
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/drop-down/prcs/depth`, param).then((response) => {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert("데이터가 정상적으로 조회되지 않았습니다.");
          return false;
        }
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.dataArray = response.data.resultData
      })
      .catch((error) => {
        console.log(error);
      });
    },
    onClickExcelDownload: function(e) {

      const vm = this
      vm.lastRouteParams.requestType = 'EXPORT'
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/prod/cost/std`, vm.lastRouteParams).then(response => {

        if (response.data.resultStatus.messageCode === '2000') {
          let resultData = response.data.resultData
          if(EtnersCommonUtil.isNotEmpty(resultData) && resultData.length > 0) {

            let costStdGridColumns = vm.costStdGridColumns.reduce((acc, cur) => {
              if(cur.field !== 'rowNumber'){ acc.push({ ...cur, width : '120px' }) }
              return acc;
            }, []);

            saveExcel({
              data: resultData,
              fileName: '원가정보이력_' + EtnersDateTimeUtil.dateToYYYYMMDD(new Date()) + '.xlsx',
              columns: costStdGridColumns
            })
          } else {
            kendo.alert('다운로드 할 자료가 없습니다.')
          }
        } else {
          kendo.alert( response.data.resultStatus.messageText)
          return false
        }
      })
    },
    validator(){
      const vm = this
      if(!vm.costStdInfo.VModelCalcStdDate){
        kendo.alert("기준일자 선택해주세요.").bind('close', function() {
          vm.$refs.calcStdDateRef.kendoWidget().open()
        })
        return
      }
      if(!vm.costStdInfo.prcsDepth1){
        kendo.alert("분할파트를 선택해주세요.").bind('close', function() {
          setTimeout(function () {
            vm.$refs.prcsDepth1Ref.widget().focus()
          }, 10)
        })
        return
      }
      if(!vm.costStdInfo.persnCost){
        kendo.alert("인건비를 입력해주세요.").bind('close', function() {
          setTimeout(function () {
            vm.$refs.persnCostRef.kendoWidget().focus()
          }, 10)
        })
        return
      }
      if(!vm.costStdInfo.finanRate){
        kendo.alert("재경비를 입력해주세요.").bind('close', function() {
          setTimeout(function () {
            vm.$refs.finanRateRef.kendoWidget().focus()
          }, 10)
        })
        return
      }
      return true
    }
  },
  data () {
    const headerAttributes = { style: 'text-align: center;', }
    const centerAttributes = { style: 'text-align: center;' }
    const rightAttributes = { style: 'text-align: right;' }
    return {
      Constant,
      ApiConfig : ApiConfig,
      lastRouteParams : {},
      locaPropsCmpyCd : '',
      costStdInfo : { isEdit : false},
      searchInfo : {},
      propsCmpyCd : '',
      dataArray : [],
      costStdGridColumns: [
        {
          field: "prcsDepth1Nm",
          title: "분할파트",
          width: "8%",
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
        },
        {
          field: "persnCost",
          title: "인건비(월급)",
          width: "10%",
          headerAttributes: headerAttributes,
          attributes: rightAttributes,
          template: function (dataItem) {
            return `${EtnersCommonUtil.numberWithCommas(dataItem.persnCost)} 원`
          }
        },
        {
          field: "finanRate",
          title: "재경비(%)",
          width: "8%",
          headerAttributes: headerAttributes,
          attributes: rightAttributes,
        },
        {
          field: "calcStdDate",
          title: "적용일자",
          width: "10%",
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
