<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>원가산출</h1></div>
    <common-area-search
        :row="2"
        :colspan="2"
        @search="commonOnChangeEvent"
        @clear="cleanSearchDataEvent"
    >
      <template v-slot:R1_C1>
        <label>회사명</label>
        <ul>
          <common-multi-drop-down
              ref="cmpyCdRef"
              @change="value=>{
                  propsCmpyCd = value
                  $refs.prcsDepth1Ref.widget().value('')
                  $refs.prcsDepth2Ref.widget().value('')
                  commonOnChangeEvent()
                }"
              :params="{searchCmpyGbnGrup : [Constant.efs.cmpyGbn.CLIENT_COMPANY]}"
              :data-columns="[{ field: 'cmpyNm', title: '회사명', width: '60%' },{ field: 'cmpyCd', title: '회사코드', width: '40%' }]"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
              :data-value-field="'cmpyCd'"
              :data-text-field="'cmpyNm'"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R1_C2>
        <label>조회일자</label>
        <ul>
          <common-range-datepicker
              ref="dtRef"
              @change="commonOnChangeEvent"
          >
          </common-range-datepicker>
        </ul>
      </template>
      <template v-slot:R2_C1>
        <label>공정파트구분</label>
        <ul>
          <common-multi-drop-down
              ref="prcsDepth1Ref"
              :params="{ searchCmpyCd : propsCmpyCd, depthRange : 1 }"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/prcs/depth`"
              :data-value-field="'prcsDepth1'"
              :data-text-field="'prcsDepth1Nm'"
              :disabled="!propsCmpyCd"
              :all-params-validator="true"
              @change="commonOnChangeEvent"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R2_C2>
        <label>공정기록구분</label>
        <ul>
          <common-multi-drop-down
              ref="prcsDepth2Ref"
              :params="{ searchCmpyCd : propsCmpyCd, depthRange : 2 }"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/prcs/depth`"
              :data-value-field="'prcsDepth2'"
              :data-text-field="'prcsDepth2Nm'"
              :disabled="!propsCmpyCd"
              :all-params-validator="true"
              @change="commonOnChangeEvent"
          >
          </common-multi-drop-down>
        </ul>
      </template>
    </common-area-search>
    <!-- //검색 영역 -->

    <section>
      <div class="contents_top_sec">
        <div class="c_t_button_wrap fl">
          <button @click="$refs.calSuitPeopleWindowRef.kendoWidget().center().open()" class="mid_btn orange mr5 ">원가산출정보</button>
        </div>
        <div class="c_t_button_wrap fr">
          <button class="mid_btn" @click="onClickExcelDownload"><i class="download_icon"></i>엑셀 다운로드</button>
        </div>
      </div>

      <div class="content_list">
        <!-- Kendo 영역 -->
        <kendo-grid ref="gridRef"
                    :data-source="computedGridRows"
                    :navigatable="false"
                    :sortable-mode="'multiple'"
                    :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
                    :reorderable="true"
                    :column-menu="false"
                    :resizable="true"
                    :selectable="'row'"
                    :columns="gridColumns"
                    :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
        />
        <!-- Kendo 영역 끝 -->
      </div>
    </section>
    <kendo-window
        ref="calSuitPeopleWindowRef"
        :title="'원가산출정보'"
        :modal="true"
        :width="'1100px'"
        style="display:none; min-height: 500px;"
        @open="e=>{this.isWindowOpen = true}"
        @close="e=>{this.isWindowOpen = false}"
    >
      <component :is="isWindowOpen ? 'StandardCostCalculation' : null"></component>
    </kendo-window>
  </div>
</template>

<script>
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import EtnersDateTimeUtil from '@ebmp-fe-common-module/module/src/common/etners.datetime.util'
import EtnersEfsUtil from '@/common/etners.efs.util'
import StandardCostCalculation from './StandardCostCalculation.vue'
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
import { saveExcel } from '@progress/kendo-vue-excel-export'
import { mapGetters } from 'vuex'
import Constant from '@/common/constant'

export default {
  name: 'CostCalcuationList',
  components : {
    StandardCostCalculation,
  },
  computed: {
    computedGridRows: function () {
      const vm = this

      const gridDataSource = new kendo.data.DataSource({
        name: 'gridDataSource',
        page: 1,
        pageSize: 10,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
          read: {
            url: `${ApiConfig.efsDomain}/efs/prod/cost`,
            type: 'GET',
            contentType: 'application/json',
            cache: false,
          },
          parameterMap: function (options, operation) {

            if (operation === 'read') {

              let filters = vm.searchFilterParam()
              let sort = EtnersEfsUtil.convertJSONArrayToJSON('sort', options.sort)

              vm.lastRouteParams = {
                loginUserToken: vm.$store.state.loginStore.lutk,
                skip: options.skip,
                take: options.take,
                page: options.page,
                pageSize: options.pageSize,
                ...sort,
                ...filters
              }
              return vm.lastRouteParams
            }

            if (operation !== 'read' && options.models) {
              return { models: kendo.stringify(options.models) }
            }
          },
        },
        schema: {
          type: 'json',
          parse: function (response) {
            let changeResultData = response.resultData
            let rowNumber = (vm.$refs.gridRef.dataSource.page() - 1)
                * vm.$refs.gridRef.dataSource.pageSize()
            for (let i = 0; i < changeResultData?.length; i++) {
              let row = changeResultData[i]
              row.rowNumber = response.total - rowNumber++
            }
            response.resultData = changeResultData
            return response
          },
          total: function (response) {
            return response.total
          },
          data: function (response) {
            if (response.resultStatus.messageCode === '2000') {
              return response.resultData
            }else if(response.resultStatus.messageCode === '4154'){
              vm.$store.dispatch('LOGOUT').then(() => {
                kendo.alert(response.resultStatus.messageText).bind('close', function() {
                  window.location.href = `/login`
                })
              })
            }else {
              kendo.alert( response.resultStatus.messageText)
              return false
            }
          },
        },

        error: function (e) {
          console.log('error event handler', e.errors[0])
        },
      })
      return gridDataSource
    },
    ...mapGetters(['loginUserData'])
  },
  mounted () {},
  methods: {
    cleanSearchDataEvent: function (ev) {
      const refs = this.$refs

      /**
       * 회사, 조회일자, 공정파트구분, 공정기록구분
       * */
      refs.cmpyCdRef?.widget().value('')
      refs.dtRef?.widget().init()
      refs.prcsDepth1Ref?.widget().value('')
      refs.prcsDepth2Ref?.widget().value('')
      this.propsCmpyCd = ''
      this.commonOnChangeEvent()

    },
    searchFilterParam: function () {
      let searchFilterOptions = {}
      const refs = this.$refs

      //회사명
      const cmpyCd = refs.cmpyCdRef.widget().value()
      if (cmpyCd) {searchFilterOptions.searchCmpyCd = cmpyCd}

      //조회일자
      const dtRefValue = refs.dtRef?.widget()?.range()
      if (dtRefValue) {
        const searchStartDt = dtRefValue.start
            ? EtnersDateTimeUtil.dateToYYYYMMDD(dtRefValue.start) : ''
        const searchEndDt = dtRefValue.end
            ? EtnersDateTimeUtil.dateToYYYYMMDD(dtRefValue.end) : ''
        searchFilterOptions.searchStartYYYYMMDD = searchStartDt
        searchFilterOptions.searchEndYYYYMMDD = searchEndDt
      }

      //공정파트구분
      const prcsDepth1 = refs.prcsDepth1Ref.widget().value()
      if (prcsDepth1) {searchFilterOptions.searchPrcsDepth1 = prcsDepth1}

      //공정기록구분
      const prcsDepth2 = refs.prcsDepth2Ref.widget().value()
      if (prcsDepth2) {searchFilterOptions.searchPrcsDepth2 = prcsDepth2}

      return searchFilterOptions ?? undefined
    },
    onChangeGrid (ev) {
      let cellIdx = window.event.target.cellIndex
      let boolForg = window.event.target.className === 'forg' // 키워드 강조 span태그 클릭시에도 deatil로 이동
      if (!isNaN(cellIdx) || boolForg) {
        let selectedRow = ev.sender.select()
        let rowData = ev.sender.dataItem(selectedRow)
        this.$router.push(
            { name: 'LocationManageDetail',
              params: { cmpyCd : rowData.cmpyCd, locaCd : rowData.locaCd }
            })
      }
    },
    commonOnChangeEvent: function () {
      const gridRef = this.$refs.gridRef
      gridRef?.kendoWidget()?.dataSource?.query({
        sort: {},
        take: 10,
        page:  1,
        pageSize:  10,
      })
    },
    onClickExcelDownload: function(e) {

      const vm = this
      vm.lastRouteParams.requestType = 'EXPORT'
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/prod/cost`, vm.lastRouteParams).then(response => {

        if (response.data.resultStatus.messageCode === '2000') {
          let resultData = response.data.resultData
          if(EtnersCommonUtil.isNotEmpty(resultData) && resultData.length > 0) {

            let customGridColumns = vm.gridColumns.reduce((acc, cur) => {
              if(cur.field !== 'rowNumber'){ acc.push({ ...cur, width : '120px' }) }
              return acc;
            }, []);

            saveExcel({
              data: resultData,
              fileName: '원가산출_' + EtnersDateTimeUtil.dateToYYYYMMDD(new Date()) + '.xlsx',
              columns: customGridColumns
            })
          } else {
            kendo.alert('다운로드 할 자료가 없습니다.')
          }
        } else {
          kendo.alert( response.data.resultStatus.messageText)
          return false
        }
      })
    },
  },
  data () {
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    const rightAttributes = { style: 'text-align: right;' }
    return {
      Constant,
      ApiConfig : ApiConfig,
      lastRouteParams : {},
      isWindowOpen : false,
      propsCmpyCd : null,
      gridColumns: [
        {
          field: 'rowNumber',
          title: 'No',
          width: '3%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          sortable: false
        },
        {
          field: 'cmpyNm',
          title: '회사명',
          width: '15%',
          headerAttributes: headerAttributes,
        },
        {
          field: 'prcsDepth1Nm',
          title: '공정파트구분',
          width: '6%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'prcsDepth2Nm',
          title: '공정기록구분',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'persnCnt',
          title: '최근작업인원(명)',
          width: '8%',
          headerAttributes: { class : 'bg_blue', ...headerAttributes },
          attributes: rightAttributes,
          template: function (dataItem) {
            return `${EtnersCommonUtil.numberWithCommas(dataItem.persnCnt)}`
          }
        },
        {
          field: 'wkrTime',
          title: '최근작업시간(H)',
          width: '8%',
          headerAttributes: { class : 'bg_blue', ...headerAttributes },
          attributes: rightAttributes
        },
        {
          field: 'prcsRgstDate',
          title: '공정적용일',
          width: '8%',
          headerAttributes: { class : 'bg_blue', ...headerAttributes },
          attributes: centerAttributes
        },
        {
          field: 'eaOfDay',
          title: '총생산량(EA)',
          width: '8%',
          headerAttributes: { class : 'bg_green', ...headerAttributes },
          attributes: rightAttributes,
          template: function (dataItem) {
            return `${EtnersCommonUtil.numberWithCommas(dataItem.eaOfDay)}`
          }
        },
        {
          field: 'eaPerHour',
          title: '시간당생산량(EA/H)',
          width: '8%',
          headerAttributes: { class : 'bg_green', ...headerAttributes },
          attributes: rightAttributes,
          template: function (dataItem) {
            return `${EtnersCommonUtil.numberWithCommas(dataItem.eaPerHour)}`
          }
        },
        {
          field: 'eaAvg',
          title: '누적 평균생산량(EA/H)',
          width: '8%',
          headerAttributes: { class : 'bg_green', ...headerAttributes },
          attributes: rightAttributes,
          template: function (dataItem) {
            return `${EtnersCommonUtil.numberWithCommas(dataItem.eaAvg)}`
          }
        },
        {
          field: 'costPerMinute',
          title: '분당생산원가(원)',
          width: '8%',
          headerAttributes: { class : 'bg_green', ...headerAttributes },
          attributes: rightAttributes,
          template: function (dataItem) {
            return `${EtnersCommonUtil.numberWithCommas(dataItem.costPerMinute)}`
          }
        },
        {
          field: 'calcDt',
          title: '원가산출일',
          width: '8%',
          headerAttributes: { class : 'bg_green', ...headerAttributes },
          attributes: centerAttributes,
        },
      ],
    }
  },
}
</script>
